import { useRouter } from 'next/router'
import { FormEventHandler, HTMLAttributes, useEffect, useState } from 'react'
import { connectSearchBox } from 'react-instantsearch-core'
import { useDebouncedCallback } from 'use-debounce'

import SearchIcon from '@/components/icons/Search'

const ConnectedInput = connectSearchBox(({ currentRefinement, refine }) => {
	const [search, setSearch] = useState(currentRefinement)
	const router = useRouter()
	const debouncedRefine = useDebouncedCallback(refine, 400)
	const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
		event.preventDefault()

		if (!window.location.pathname.startsWith('/search')) {
			const url = `/search${search === '' ? '' : `?q=${search}`}`

			void router.push(url)
		}
	}

	useEffect(() => {
		setSearch(current => {
			if (currentRefinement.trim() === current.trim()) {
				return current
			}

			debouncedRefine.cancel()
			return currentRefinement
		})
	}, [currentRefinement, debouncedRefine])

	return (
		<form onSubmit={handleSubmit} className="flex">
			<input placeholder="Search Pace..." className="flex-1 h-7 shadow-input appearance-none rounded-l-full text-sm px-4 py-1 ring-offset-gray-800 sm:ml-4 md:h-8 md:text-base lg:h-10 focus:z-10" value={search} onChange={e => {
				setSearch(e.target.value)
				debouncedRefine(e.target.value)
			}} data-hj-allow />
			<button className="shrink-0 h-7 w-7 flex justify-center items-center bg-gradient-to-b from-green-400 via-green-500 to-green-500 rounded-r-full ring-offset-gray-800 md:h-8 md:w-8 lg:h-10 lg:w-10 focus:z-10">
				<SearchIcon className="text-white h-3.5 md:h-4 lg:h-5" />
			</button>
		</form>
	)
})

export default function SearchInput(attributes: HTMLAttributes<HTMLDivElement>): JSX.Element {
	return (
		<div {...attributes}>
			<ConnectedInput />
		</div>
	)
}
