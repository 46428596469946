import { Menu } from '@headlessui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useRef } from 'react'
import { useEffectOnce } from 'react-use'

import AccountIcon from '@/components/icons/header/Account'
import CartIcon from '@/components/icons/header/Cart'
import PhoneIcon from '@/components/icons/header/Phone'
import SmallLogoIcon from '@/components/icons/header/SmallLogo'
import LogoIcon from '@/components/icons/Logo'
import { useAuthentication } from '@/components/providers/AuthenticationProvider'
import SearchInput from '@/components/SearchInput'

function AccountMenu(): JSX.Element {
	const router = useRouter()
	const button = useRef<HTMLButtonElement | null>(null)
	const isOpen = useRef(false)
	const accountLinks = [
		{ link: '/account', label: 'Dashboard' },
		{ link: '/account/info', label: 'Manage' },
		{ link: '/account/invoices', label: 'Invoices' },
		{ link: '/account/orders', label: 'Orders' },
		{ link: '/account/payments', label: 'Payments' },
		{ link: '/account/shipments', label: 'Shipments' },
		{ link: '/account/favorites', label: 'Favorites' },
		{ link: '/logout', label: 'Sign Out' },
	]

	useEffectOnce(() => {
		if (process.browser) {
			const handler = () => {
				if (isOpen.current) {
					button.current?.click()
				}
			}

			router.events.on('routeChangeComplete', handler)
			return () => router.events.off('routeChangeComplete', handler)
		}
	})

	return (
		<Menu>
			{({ open }) => {
				isOpen.current = open

				return (
					<div className="relative shrink-0 ml-5 sm:ml-9">
						<Menu.Button ref={button} className="text-white flex items-center ring-offset-gray-800">
							<AccountIcon className="h-6 sm:h-7 md:h-8 lg:h-10" />
							<span className="hidden ml-2 text-sm md:block">My Account</span>
						</Menu.Button>
						<Menu.Items className="absolute -right-4 min-w-full whitespace-nowrap top-full mt-2 bg-white shadow-md rounded-xl divide-y divide-gray-200 overflow-hidden flex flex-col focus:ring-0">
							{accountLinks.map(link => (
								<Menu.Item key={link.label}>
									<Link href={link.link}>
										<a className="px-2.5 py-0.5 hover:bg-green-200">{link.label}</a>
									</Link>
								</Menu.Item>
							))}
						</Menu.Items>
					</div>
				)
			}}
		</Menu>
	)
}

export default function Header(): JSX.Element {
	const { email } = useAuthentication()
	const isLoggedIn = typeof email === 'string'

	return (
		<>
			<aside className="hidden bg-gray-800 relative z-40 pb-1 sm:block">
				<div className="container mx-auto pt-3 px-8">
					<div className="flex items-center">
						<a className="text-xs text-white flex items-center space-x-2 z-10 ring-offset-gray-800" href="tel:18004447223">1-800-444-PACE (7223)</a>

						<Link href="/account/serial-lookup">
							<a className="ml-auto text-white text-xs underline ring-offset-gray-800">Serial Lookup</a>
						</Link>

						<div className="ml-2 w-px h-3 bg-green-400 rounded-full"></div>

						<a href="https://paceintl.info/login24281929" target="_blank" rel="noreferrer noopener" className="ml-2 text-white text-xs underline ring-offset-gray-800">RV Dealer Login</a>
					</div>
				</div>
			</aside>

			<header className="bg-gray-800 sticky top-0 z-30 sm:-mt-1">
				<div className="container mx-auto px-4 py-4 space-y-5 sm:hidden">
					<div className="flex items-center">
						<Link href="/">
							<a className="text-white ring-offset-gray-800">
								<SmallLogoIcon className="h-6" />
							</a>
						</Link>
						<a className="ml-auto text-white ring-offset-gray-800" href="tel:18004447223">
							<PhoneIcon className=" h-6 w-6" />
						</a>
						{isLoggedIn ? (
							<AccountMenu />
						) : (
							<Link href="/login">
								<a className="ml-5 text-white ring-offset-gray-800">
									<AccountIcon className="h-6 w-6" />
								</a>
							</Link>
						)}
						<Link href="/cart">
							<a className="ml-5 ring-offset-gray-800">
								<CartIcon className="text-white h-6 w-6" />
							</a>
						</Link>
					</div>

					<SearchInput className="flex-1" />
				</div>

				<div className="hidden container mx-auto px-8 py-5 sm:block">
					<div className="flex items-center">
						<Link href="/">
							<a className="shrink-0 text-white ring-offset-gray-800 focus:ring-0">
								<LogoIcon className="h-7 md:h-8 lg:h-10" />
							</a>
						</Link>

						<SearchInput className="ml-4 flex-1 md:ml-9 lg:ml-12" />

						{isLoggedIn ? (
							<AccountMenu />
						) : (
							<Link href="/login">
								<a className="shrink-0 ml-9 text-white flex items-center ring-offset-gray-800">
									<AccountIcon className="h-7 md:h-8 lg:h-10" />
									<span className="hidden ml-2 text-sm md:block">Sign In</span>
								</a>
							</Link>
						)}
						<Link href="/cart">
							<a className="shrink-0 ml-7 ring-offset-gray-800">
								<CartIcon className="text-white h-7 md:h-8 lg:h-10" />
							</a>
						</Link>
					</div>
				</div>
			</header>

			<aside className="bg-gray-200 hidden md:block">
				<div className="container mx-auto py-0.5 px-8">
					<div className="flex justify-center items-center h-8 relative lg:h-10">
						<p className="text-sm font-semibold text-gray-800">Proudly serving the technology and telecommunications industries for over 50 years.</p>
					</div>
				</div>
			</aside>
		</>
	)
}
