import { SVGAttributes } from 'react'

export default function SmallLogoIcon(attributes: SVGAttributes<SVGSVGElement>): JSX.Element {
	return (
		<svg {...attributes} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 24">
			<path d="M9.578 3h19.275c2.847 0 4.27.89 4.27 2.669v3.35c0 1.75-1.423 2.61-4.24 2.61h-13.7v5.308H9.578V3zm16.132 7.176c.682 0 1.127-.089 1.394-.237.237-.148.385-.445.385-.86v-3.5c0-.414-.119-.71-.385-.86-.267-.147-.712-.236-1.394-.236H15.183v5.693H25.71z" fill="currentColor" />
			<path className="text-green-400" d="M28.468 13.794c7.265.771 9.133 2.283 9.133 2.906 0 1.512-8.154 3.173-19.838 3.173-7.443 0-14.323-.474-17.793-1.601C3.114 19.725 11.298 21 20.52 21c10.736 0 22.152-1.364 22.152-3.885.03-2.55-10.853-3.084-14.204-3.32z" fill="currentColor" />
		</svg>
	)
}
