import { SVGAttributes } from 'react'

export default function AccountIcon(attributes: SVGAttributes<SVGSVGElement>): JSX.Element {
	return (
		<svg {...attributes} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
			<path d="M18 36C8.1 36 0 27.9 0 18S8.1 0 18 0s18 8.1 18 18-8.1 18-18 18zm0-34.8C8.8 1.2 1.2 8.8 1.2 18c0 9.2 7.6 16.8 16.8 16.8 9.2 0 16.8-7.6 16.8-16.8 0-9.2-7.6-16.8-16.8-16.8z" fill="#fff" />
			<path d="M18 19.3c-2.8 0-5.1-2.9-5.1-6.4 0-4.4 1.6-6.4 5.1-6.4 3.5 0 5.1 2 5.1 6.4 0 3.5-2.3 6.4-5.1 6.4zm0-11.5c-3.2 0-3.8 2-3.8 5.1 0 2.8 1.7 5.1 3.8 5.1 2.1 0 3.8-2.3 3.8-5.1.1-4.2-1.4-5.1-3.8-5.1zM18 29.6c-2.4 0-4.7-.1-6.4-.4-2.2-.4-3.1-.8-3.1-1.6s.2-4.7 1.5-5.8c1.1-.9 4.5-2.4 5.1-2.3.3 0 .7.3 1 .5.1.1.2.2.4.2.3.2.7.3.8.3.1 0 .5.1.7.1.2 0 .5 0 .7-.1.1 0 .5-.1.8-.3.1-.1.2-.1.4-.2.4-.2.7-.5 1-.5.6-.1 4 1.4 5.1 2.3 1.3 1.1 1.5 5 1.5 5.8 0 .8-.9 1.3-3.1 1.6-1.7.2-4 .4-6.4.4zm-8.3-2.2c.6.4 3.4.9 8.3.9s7.7-.6 8.3-.9c-.1-2.7-.7-4.4-1-4.7-1-.8-3.6-1.9-4.2-2-.1.1-.3.2-.5.3-.1.1-.3.2-.4.2-.6.3-1.2.4-1.3.4-.1 0-.5.1-.8.1-.3 0-.8-.1-.8-.1-.1 0-.7-.1-1.3-.4-.1-.1-.3-.2-.4-.3-.2-.1-.4-.2-.5-.3-.6.2-3.2 1.2-4.2 2-.5.4-1.1 2.1-1.2 4.8z" fill="#fff" />
		</svg>
	)
}
