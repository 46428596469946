import { SVGAttributes } from 'react'

export default function LogoIcon(attributes: SVGAttributes<SVGSVGElement>): JSX.Element {
	return (
		<svg {...attributes} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 684 164">
			<path d="M157.797 64.969h70.737c10.447 0 15.67 3.265 15.67 9.794V87.06c0 6.421-5.223 9.577-15.562 9.577h-50.168v19.48h-20.677V64.969zm59.201 26.336c2.503 0 4.135-.327 5.115-.871.87-.544 1.415-1.632 1.415-3.156v-12.95c0-1.524-.436-2.612-1.415-3.156-.871-.544-2.612-.87-5.115-.87h-38.633v20.894h38.633v.109zm57.786 24.812h-10.447l43.53-51.148h18.718l40.375 51.148h-23.289L310.588 74.11l-35.804 42.007zm129.176 0c-10.447 0-15.671-3.156-15.671-9.577V74.763c0-6.53 5.224-9.794 15.671-9.794h57.895v5.441h-46.359c-2.503 0-4.136.327-5.115.87-.871.545-1.415 1.633-1.415 3.157v32.103c0 1.632.436 2.721 1.415 3.265.871.544 2.612.87 5.115.87h46.359v5.551H403.96v-.109zm83.034-51.148h72.26v5.441h-51.692v16.65h51.692v5.442h-51.692v18.065h51.91v5.55h-72.369V64.969h-.109z" fill="currentColor" />
			<path d="M327.238 98.705c-34.497 0-62.466-7.074-62.466-15.78 0-5.659 11.754-10.556 29.383-13.386-25.247 2.395-43.203 8.271-43.203 15.236 0 9.032 30.362 16.324 67.689 16.324 29.383 0 54.413-4.462 63.772-10.774-10.556 5.006-31.233 8.38-55.175 8.38z" fill="currentColor" />
			<path className="text-green-400" d="M396.56 12.624c-179.127 0-324.3 36.674-324.3 81.945 0 29.274 60.942 54.957 152.356 69.431C93.263 151.703 0 121.014 0 84.884 0 37.98 157.579 0 351.942 0c152.573 0 282.511 23.398 331.264 56.263-54.521-26.01-162.367-43.64-286.646-43.64z" fill="currentColor" />
			<path d="M566.654 111.437h-1.632v-.87h4.353v.87h-1.632v4.571h-.98v-4.571h-.109zm3.265-.87h1.524l1.414 3.809 1.524-3.809h1.523v5.55h-.979v-4.571l-1.632 4.571h-.653l-1.633-4.571v4.571h-.979v-5.55h-.109z" fill="currentColor" />
		</svg>
	)
}
