import algoliasearch from 'algoliasearch/lite'
import { useRouter } from 'next/router'
import { FunctionComponent, useCallback, useState } from 'react'
import { InstantSearch } from 'react-instantsearch-dom'
import { useEffectOnce } from 'react-use'

import { calculateUrl, parseUrl } from '@/utils/search'

declare const process: {
	browser: boolean,
	env: {
		NEXT_PUBLIC_ALGOLIA_ID: string | undefined,
		NEXT_PUBLIC_ALGOLIA_KEY: string | undefined,
	},
}

interface SearchState {
	hierarchicalMenu?: {
		'categories.lvl0': string,
	},
	page?: number,
	query?: string,
	refinementList?: {
		brand: Array<string>,
	},
}

const searchClient = algoliasearch(process.env.NEXT_PUBLIC_ALGOLIA_ID!, process.env.NEXT_PUBLIC_ALGOLIA_KEY!)

const createUrl = (state: SearchState) => {
	const url = calculateUrl(
		state.query ?? '',
		state.hierarchicalMenu?.['categories.lvl0'] ?? null,
		state.refinementList?.brand ?? [],
	)

	return url
}

const urlToSearchState = (oldState: SearchState = {}) => {
	const url = process.browser ? `${window.location.pathname.replace(/\/search\/?/u, '')}${window.location.search}` : ''
	const { query, category, brands } = parseUrl(url)

	return {
		...oldState,

		query,

		...brands.length === 0 ? {
			refinementList: undefined,
		} : {
			refinementList: { brand: brands },
		},
		...category === null ? {
			hierarchicalMenu: undefined,
		} : {
			hierarchicalMenu: { 'categories.lvl0': category },
		},
	}
}

export const SearchProvider: FunctionComponent = ({ children }) => {
	const router = useRouter()
	const [searchState, setSearchState] = useState<SearchState>(urlToSearchState)

	const handleSearchStateChange = useCallback((updatedSearchState: SearchState) => {
		setSearchState(updatedSearchState)
		if (window.location.pathname.startsWith('/search')) {
			const url = `/search${createUrl(updatedSearchState)}`

			void router.push(url, undefined, { scroll: updatedSearchState.page === 1 })
		}
	}, [])

	useEffectOnce(() => {
		const handler = () => {
			setSearchState(urlToSearchState)
		}

		router.events.on('routeChangeComplete', handler)
		return () => router.events.off('routeChangeComplete', handler)
	})

	return (
		<InstantSearch
			searchClient={searchClient}
			indexName="products"
			searchState={searchState}
			onSearchStateChange={handleSearchStateChange}
			createURL={createUrl}
		>
			{children}
		</InstantSearch>
	)
}
