import { ReactNode } from 'react'

import Footer from '@/components/Footer'
import Header from '@/components/Header'
import { SearchProvider } from '@/components/providers/SearchProvider'
import { combineProviders } from '@/utils/providers'

const Providers = combineProviders([
	SearchProvider,
])

export default function Layout({ children }: { children: ReactNode }): JSX.Element {
	return (
		<Providers>
			<Header />

			<main className="text-gray-800">
				{children}
			</main>

			<Footer />
		</Providers>
	)
}
