import { SVGAttributes } from 'react'

export default function PhoneIcon(attributes: SVGAttributes<SVGSVGElement>): JSX.Element {
	return (
		<svg {...attributes} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path d="M7.8 5.5c.4 0 .7.3.9.5.2.3 1.6 2.5 1.6 2.5.2.4 0 .7-.2.9-.2.2-1 .7-1.1 1.3-.1.6.9 1.9 1.7 2.7.7.7 2 1.7 2.6 1.7h.1c.6-.1 1.1-.9 1.3-1.1.2-.2.4-.3.6-.3.1 0 .2 0 .3.1 0 0 2.2 1.4 2.5 1.6.3.2.7.7.4 1.4-.3.6-1 1.8-2.7 1.8h-.1c-1.7 0-3.8-.4-6.8-3.4s-3.3-5.1-3.4-6.8C5.4 6.7 6.6 6 7.3 5.7c.1-.1.3-.2.5-.2zm0-.7c-.3 0-.6.1-.9.2-.6.3-2.3 1.3-2.2 3.5.1 2 .5 4.2 3.6 7.3 3 3 5.3 3.5 7.3 3.6h.1c2.1 0 3-1.6 3.4-2.2.3-.5.3-1 .1-1.5-.2-.5-.5-.7-.7-.9-.3-.2-2.5-1.6-2.5-1.6-.4-.2-.6-.2-.9-.2-.4 0-.8.2-1.1.5-.1.1-.1.2-.2.3-.1.1-.4.5-.6.6-.3-.1-1.2-.6-2-1.5-.8-.8-1.4-1.7-1.5-2 .1-.2.5-.5.6-.6.1-.1.2-.2.3-.2.5-.5.6-1.2.3-1.8 0 0-1.4-2.2-1.6-2.5-.3-.6-.8-1-1.5-1z" fill="currentColor" />
			<path d="M12 24C5.4 24 0 18.6 0 12S5.4 0 12 0s12 5.4 12 12-5.4 12-12 12zM12 .8C5.8.8.8 5.8.8 12c0 6.2 5 11.2 11.2 11.2 6.2 0 11.2-5 11.2-11.2C23.2 5.8 18.2.8 12 .8z" fill="currentColor" />
		</svg>
	)
}
