import { Dispatch, SetStateAction, useState } from 'react'

export enum Status {
	Idle,
	Loading,
	Success,
	Error,
}

export function useStatus(status: Status): [Status, Dispatch<SetStateAction<Status>>] {
	return useState(status)
}
