import { SVGAttributes } from 'react'

export default function SupportIcon(attributes: SVGAttributes<SVGSVGElement>): JSX.Element {
	return (
		<svg {...attributes} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
			<path className="text-gray-800" d="M32 64c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32z" fill="currentColor"/>
			<path className="text-green-400" d="M44.7 27.5c0 3.8-5.4 4-10.3 4-.2.3-.6.4-.9.4h-1.8c-.7 0-1.2-.6-1.2-1.2 0-.7.6-1.2 1.2-1.2h1.8c.5 0 .9.3 1.1.6 5.7 0 8.8-.5 8.8-2.6h1.3z" fill="currentColor"/>
			<path className="text-white" d="M32 8.7c-8.8 0-12.7 4.3-12.7 13.8h1.4c0-8.8 3.3-12.4 11.3-12.4s11.3 3.6 11.3 12.4h1.4C44.7 13 40.8 8.7 32 8.7z" fill="currentColor"/>
			<path className="text-gray-300" d="M34.7 32.6c-.4.3-.8.4-1.3.4h-1.7c-1.3 0-2.3-1.1-2.3-2.4 0-1.3 1-2.4 2.3-2.4h1.7c.6 0 1.1.2 1.6.6 1.4 0 3.6-.1 5.2-.4.5-1.5.9-3.2.9-5 0-7.3-2.8-11.1-9-11.1s-9 3.8-9 11.1c0 6.5 4 11.8 9 11.8 2.2 0 4.2-1.1 5.8-2.8-1.2.2-2.2.2-3.2.2z" fill="currentColor"/>
			<path className="text-green-400" d="M33.4 29.5h-1.8c-.7 0-1.2.6-1.2 1.2 0 .7.6 1.2 1.2 1.2h1.8c.4 0 .7-.2.9-.4 1.5 0 3.1 0 4.6-.2l.9-1.5c-1.4.2-3.2.3-5.3.3-.2-.4-.6-.6-1.1-.6z" fill="currentColor"/>
			<path className="text-white" d="M20.6 28.2h-1.2c-.6 0-1.2-.5-1.2-1.2v-7.4c0-.6.5-1.2 1.2-1.2h1.2c.6 0 1.2.5 1.2 1.2V27c0 .6-.5 1.2-1.2 1.2zM44.6 28.2h-1.2c-.6 0-1.2-.5-1.2-1.2v-7.4c0-.6.5-1.2 1.2-1.2h1.2c.6 0 1.2.5 1.2 1.2V27c0 .6-.6 1.2-1.2 1.2z" fill="currentColor"/>
			<path className="text-gray-300" d="M47.6 40.4c-2.4-2-9.3-5.6-9.9-5.6-.5.1-1.1.8-2 1.3-1 .6-1.7.4-2.2.7-.8.7-1.6 3.9-1.6 3.9s-.8-3.2-1.6-3.9c-.4-.4-1.2-.2-2.2-.7-.9-.5-1.5-1.3-2-1.3-.5-.1-7.4 3.6-9.9 5.6-2.4 2-2.6 10.9-2.6 10.9 0 1.6 8.2 2.9 18.3 2.9 10.1 0 18.3-1.3 18.3-2.9.1.1-.1-8.9-2.6-10.9z" fill="currentColor"/>
		</svg>
	)
}
