import { transform } from './string'

export const calculateUrl = (query: string, category: string | null, brands: Array<string>): string => transform(`/${[
	transform((category ?? '').split(' > ').join('--'), [[/ /gu, '-']]),

	brands.length > 0 ? `b/${brands.map(brand => transform(brand, [[/-/gu, '_'], [/ /gu, '-']])).join('/')}` : '',

	query === '' ? '' : `?q=${query}`,
].join('/')}`, [
	[/\/{2,}/gu, '/'],
	[/^\/\?/gu, '?'],
	[/\/$/gu, ''],
])

export const parseUrl = (url: string): {query: string, category: string | null, brands: Array<string> } => {
	let parts = decodeURI(url).split('?')
	const params = new URLSearchParams(parts.length === 2 ? parts[1] : '')
	const query = params.get('q') ?? ''

	parts = parts[0].split(/\/?b\//u)
	const brands = parts.length === 2 ? transform(parts[1], [[/-/gu, ' '], [/_/gu, '-']]).split('/') : []

	const category = transform(parts[0], [[/--/gu, ' > '], [/-/gu, ' ']])

	return {
		query,
		category: category === '' ? null : category,
		brands,
	}
}
